import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import "./SearchInput.css";

export default class SearchInput extends PureComponent {
  static propTypes = {
    textChange: PropTypes.func,
    brandName: PropTypes.string
  };

  handleChange = event => {
    this.props.textChange(event);
  };

  render() {
    return (
      <>
      <div className = "results-title">
      Sélectionnez le modèle de votre smartphone {this.props.brandName}
      </div>
      <div className="component-search-input">
          <div>
            <input id="search" type="text" onChange={this.handleChange} placeholder="Ou saissisez le modèle..." />
          </div>
      </div>
      </>
    );
  }
}
