import React, { PureComponent } from "react";
import Calendar from './Calendar.js'
import "./FinalPage.css";

export default class FinalPage extends PureComponent {

  addRecapItems() {
    return(
      <div className="component-recap-bloc">
        <div className="component-recap-bloc-image">
            {this.printImg()}
        </div>
        <div className="component-recap-bloc-model">
          <div className="component-recap-bloc-model-title">
            {this.printModel()}
          </div>
          <div>
            {this.printSelectedItems()}
          </div>
        </div>
        <div className="component-recap-bloc-prices">
          Total
          <div className="component-recap-bloc-totalAmount">
            {this.printTotal()}
          </div>
          <div>
            {this.printDiscount()}
          </div>
        </div>
      </div>
    );
  }

  printImg() {
    const idPhone = parseInt(this.props.model.IDModele);
    if(idPhone === -1)
      return <img alt ={this.props.category.LibelleCategorie} src={this.props.category.URL_IMG} />;

    return <img alt ={this.props.model.LibelleModele} src={this.props.model.URL_IMG} />;
  }

  printModel() {
    const idCat = parseInt(this.props.category.IDCategorie);
    const idBrand = parseInt(this.props.brand.id);
    const idPhone = parseInt(this.props.model.IDModele);
    //console.log("idCat : "+idCat+" / idBrand : "+idBrand+ " / idPhone : "+idPhone);

    //Catégories autres que "Téléphone" ou (catgéorie "Téléphone" et marque "Autre")
    if(idCat !== 2 || idBrand === 0)
      return(this.props.category.LibelleCategorie);

    //Catégorie "Téléphone" et modèle "autre"
    if(idPhone < 1)
      return(this.props.category.LibelleCategorie + " " + this.props.brand.name);

    return(this.props.model.LibelleMarque + " "+this.props.model.LibelleModele);
  }

  printSelectedItems() {
    const items = this.props.selectedItems;

    if(items.length === 0)
      return "";

    var labels = "Réparation(s) : ";

    for(var i=0 ; i<items.length ; i++)
      labels += items[i].Piece + " & ";

    return labels.substring(0, labels.length-3);
  }

  printTotal() {
    if(this.props.total === 0)
      return "Sur devis";
    return this.props.total+"€";
  }

  printDiscount() {
    return "("+this.props.discount +"€ de réduction)";
  }

  render() {
    return (
      <>

      <div className="end-message">
        <h4> Votre demande de devis a bien été prise en compte, </h4>
        <div className="component-recap-page">
          {this.addRecapItems()}
        </div>
        <h4> notre équipe vous recontactera au plus vite pour prendre rendez-vous !</h4>
        <h4>
          <div className=" end-message end-message-button">
            <a href="/"><button>Générer un nouveau devis</button></a>
            ou
            <a href="tel:0257740076"><button>Contacter notre équipe</button></a>
            ou
            <a href="https://www.phoner.co/acheter-son-smartphone-35"><button>Visiter notre boutique</button></a>
          </div>
        </h4>
        <h4> A très vite ! </h4>
        <h4> L'équipe Phoner 🚀 </h4>
        </div>
      </>);
  }
}
