import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import DataForm from './DataForm';
import APIUtilities from './APIUtilities.js'

import "./RecapPage.css";

const styles = theme => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderWidth: 2,
        borderColor: '#D9D9D9',
      },
      '&:hover fieldset': {
        borderColor: '#205685',
      },
    }
  }
});

class RecapPage extends PureComponent {
  static propTypes = {
    category: PropTypes.object,
    brand: PropTypes.object,
    model: PropTypes.object,
    selectedItems: PropTypes.array,
    discount: PropTypes.number,
    total: PropTypes.number,
    GUID: PropTypes.string,
    onClickFunction: PropTypes.func
  };

  constructor () {
    super();
    this.state = {
      discountHelperText: '',
      discountError: false,
      discountCode: '',
      discountCodeAmount: 0
    };
    this.sendDevis = this.sendDevis.bind(this);
    this.validatePromoCode = this.validatePromoCode.bind(this);
  }

  addRecapItems() {
    return(
      <div className="component-recap-bloc">
        <div className="component-recap-bloc-image">
            {this.printImg()}
        </div>
        <div className="component-recap-bloc-model">
          <div className="component-recap-bloc-model-title">
            {this.printModel()}
          </div>
          <div>
            {this.printSelectedItems()}
          </div>
        </div>
        <div className="component-recap-bloc-prices">
          Total
          <div className="component-recap-bloc-totalAmount">
            {this.printTotal()}
          </div>
          <div>
            {this.printDiscount()}
          </div>
        </div>
      </div>
    );
  }

  printImg() {
    const idPhone = parseInt(this.props.model.IDModele);
    if(idPhone === -1)
      return <img alt ={this.props.category.LibelleCategorie} src={this.props.category.URL_IMG} />;

    return <img alt ={this.props.model.LibelleModele} src={this.props.model.URL_IMG} />;
  }

  printModel() {
    const idCat = parseInt(this.props.category.IDCategorie);
    const idBrand = parseInt(this.props.brand.id);
    const idPhone = parseInt(this.props.model.IDModele);
    //console.log("idCat : "+idCat+" / idBrand : "+idBrand+ " / idPhone : "+idPhone);

    //Catégories autres que "Téléphone" ou (catgéorie "Téléphone" et marque "Autre")
    if(idCat !== 2 || idBrand === 0)
      return(this.props.category.LibelleCategorie);

    //Catégorie "Téléphone" et modèle "autre"
    if(idPhone < 1)
      return(this.props.category.LibelleCategorie + " " + this.props.brand.name);

    return(this.props.model.LibelleMarque + " "+this.props.model.LibelleModele);
  }

  printSelectedItems() {
    const items = this.props.selectedItems;

    if(items.length === 0)
      return "Réparation à détailler dans le bloc \"Votre demande\"";

    var labels = "Réparation(s) : ";

    for(var i=0 ; i<items.length ; i++)
      labels += items[i].Piece + " & ";

    return labels.substring(0, labels.length-3);
  }

  printTotal() {
    if(this.props.total === 0)
      return "Sur devis";
    if(this.props.showPrices === false)
      return "Page suivante..."
    return (this.props.total-this.state.discountCodeAmount)+"€";
  }

  printDiscount() {
    return "("+(this.props.discount+this.state.discountCodeAmount) +"€ de réduction)";
  }

  addPromoCode() {
    const {classes} = this.props;
    return(
      <form
        className={classes.root+" component-promo-code"}
        noValidate
        autoComplete="off"
        onSubmit={this.validatePromoCode}>
        <div>
          <TextField
          className = "discountField"
            id="discountCode"
            label="Code de réduction"
            error={this.state.discountError}
            helperText={this.state.discountHelperText}
            variant="outlined"/>
          </div>
        <div>
        <button className="validate-button" id="validate-promo-code-button" variant="contained" label="Submit" type="submit">Vérifier le code</button>
      </div>
      </form>
    );
  }

  async validatePromoCode(event) {
    event.preventDefault();
    const code = event.target.discountCode.value;
    var discountCode = '';
    var discountHelperText = 'Ce code est invalide';
    var discountError = true;
    const discountCodeAmount = await APIUtilities.validatePromoCode(code);

    if(discountCodeAmount > 0) {
      discountHelperText = "Ce code vous fait bénéficier de "+discountCodeAmount+"€ de réduction."
      discountError = false;
      discountCode = code;
    }

    this.setState({
      discountCode : discountCode,
      discountCodeAmount : discountCodeAmount,
      discountHelperText: discountHelperText,
      discountError: discountError
    });
  };

  async sendDevis(event, site) {
    event.preventDefault();
    var details = "";

    if(this.props.selectedItems.length === 0)
      details = event.target.details.value;

    await APIUtilities.sendDevis(event.target.name.value,
                                 event.target.email.value,
                                 event.target.phone.value.replace(/ /g, ""),
                                 site,
                                 this.props.GUID,
                                 this.props.selectedItems,
                                 details,
                                 this.state.discountCode);

    this.props.onClickFunction(this.state.discountCodeAmount);
  }

  render() {
    return (
      <>
      <div className="component-recap-page">
        <h1 className="component-recap-page-title">Récapitulatif</h1>
        {this.addRecapItems()}
        {this.addPromoCode()}
        <DataForm onSubmit={this.sendDevis} details={this.props.selectedItems.length === 0 ? 1 : 0}/>
      </div>
      </>
    );
  }
}

export default withStyles(styles)(RecapPage);
