import React, { PureComponent } from "react";
import SearchInput from './SearchInput'
import CategoriesResults from "./CategoriesResults.js"
import BrandResults from "./BrandResults.js"
import PhoneResults from "./PhoneResults";
import PhoneData from "./PhoneData.js";
import RecapPage from "./RecapPage.js";
import FinalPage from './FinalPage.js'
import APIUtilities from './APIUtilities.js'
import uuid from 'react-uuid';

import "./App.css";

const CATEGORY_CHOICE = 1;
const BRAND_CHOICE = 2;
const MODEL_CHOICE = 3;
const PHONE_DATA = 4;
const RECAP = 5;
const FINAL_PAGE = 6;

const TABLET = 1;
const PC = 3;
const OBJECT = 4;

const NONE = -1;

export default class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentState: CATEGORY_CHOICE,
      currentModel: {"IDModele" : NONE},
      currentSearch: NONE+"",
      GUID: uuid(),
      showPrices: false
    };

    //this.handleClickOnAriane = this.handleClickOnAriane.bind(this);
    this.handleClickOnCategory = this.handleClickOnCategory.bind(this);
    this.handleClickOnBrand = this.handleClickOnBrand.bind(this);
    this.handleClickOnPhone = this.handleClickOnPhone.bind(this);
    this.handleClickOnValidateItems = this.handleClickOnValidateItems.bind(this);
    this.handleClickOnSubmitDevis = this.handleClickOnSubmitDevis.bind(this);
    this.clickReturnButton = this.clickReturnButton.bind(this);
  };

  /*
  handleClickOnAriane(event) {
    alert("Ariane");
  }*/

  handleClickOnCategory(category) {
    var nextState = BRAND_CHOICE;
    const id = parseInt(category.IDCategorie);

    if(id === TABLET || id === PC || id === OBJECT)
      nextState = RECAP;

    this.setState({
      currentCategory: category,
      currentBrand: {"id" : NONE},
      currentModel: {"IDModele" : NONE},
      selectedItems: [],
      discount: 0,
      total: 0,
      currentState: nextState
    });

  };

  handleClickOnBrand(id, name) {
    var nextState = MODEL_CHOICE;

    if(id === 0) /* Clique sur marque "Autre" */
      nextState = RECAP;

      this.setState({
        currentState: nextState,
        currentBrand: {"id" : id, "name" : name},
        currentModel: {"IDModele" : NONE},
        selectedItems: [],
        discount: 0,
        total: 0
      });
  };

  handleClickOnPhone(selectedPhone) {
    var nextState = PHONE_DATA;

    if(parseInt(selectedPhone.IDModele) === 0) /* Clique sur modele "Autre" */
      nextState = RECAP;

    this.setState({
      currentState: nextState,
      currentModel: selectedPhone,
      selectedItems: [],
      discount: 0,
      total: 0
    });
  };

  clickReturnButton() {
    const currentState = this.state.currentState;
    const currentCategoryID = parseInt(this.state.currentCategory.IDCategorie);
    var nextState = currentState-1;

    if(currentState === RECAP) {
      if(currentCategoryID === TABLET || currentCategoryID === PC || currentCategoryID === OBJECT)
        nextState = CATEGORY_CHOICE;

      else if(this.state.currentBrand.id === 0)
        nextState = BRAND_CHOICE;

      else if(parseInt(this.state.currentModel.IDModele) === 0)
        nextState = MODEL_CHOICE;
    }

    this.setState({
      currentState : nextState,
      currentSearch: NONE+"",
    });
  };

  handleClickOnValidateItems(selectedItems, discount, total) {
    if(selectedItems.length === 0) {
      alert("Veuillez selectionner au moins 1 réparation afin de continuer");
      return;
    }

    APIUtilities.sendPreDevis(this.state.GUID, selectedItems);

    this.setState({
      currentState: RECAP,
      selectedItems: selectedItems,
      discount: discount,
      total: total
    });
  };

  handleClickOnSubmitDevis(discountCodeAmount) {
    this.setState({
      currentState: FINAL_PAGE,
      discount: this.state.discount+discountCodeAmount,
      total: this.state.total-discountCodeAmount
    });
  }

  /*Gestion du changement de text dans la barre de recherche*/
  handleSearchChange = event => {
    var val = event.target.value;

    if(val === "")
      val = NONE+"";

    this.setState({currentSearch: val});
  };

  addReturnButton() {
    if(this.state.currentState > CATEGORY_CHOICE && this.state.currentState < FINAL_PAGE)
      return (<button className="return-button" onClick={this.clickReturnButton}>Retour</button>);
  }

  addSearchInput() {
    if(this.state.currentState === MODEL_CHOICE)
      return <SearchInput textChange={this.handleSearchChange} brandName={this.state.currentBrand.name}/>;
  }

  getCurrentContent() {
    switch(this.state.currentState) {
    case CATEGORY_CHOICE:
      return <CategoriesResults onClickFunction={this.handleClickOnCategory}/>;

    case BRAND_CHOICE:
      return <BrandResults onClickFunction={this.handleClickOnBrand}/>;

    case MODEL_CHOICE:
      return <PhoneResults brandID={this.state.currentBrand.id} search={this.state.currentSearch} onClickFunction={this.handleClickOnPhone}/>;

    case PHONE_DATA:
      return <PhoneData phoneData={this.state.currentModel} onClickFunction={this.handleClickOnValidateItems} showPrices={this.state.showPrices}/>;

    case RECAP:
      return <RecapPage category={this.state.currentCategory} brand={this.state.currentBrand} model={this.state.currentModel}
        selectedItems={this.state.selectedItems} discount={this.state.discount} total={this.state.total} GUID={this.state.GUID}
        onClickFunction={this.handleClickOnSubmitDevis} showPrices={this.state.showPrices}/>;

    case FINAL_PAGE:
      return (<FinalPage category={this.state.currentCategory} brand={this.state.currentBrand} model={this.state.currentModel}
        selectedItems={this.state.selectedItems} discount={this.state.discount} total={this.state.total}/>);

    default:
      console.log("default");
      return "";
    }
  }

  render() {
    return (
      <div>
        {/*
        Fonctionnalités supplémentaires V2
        <Ariane onClickFunction={this.handleClickOnAriane}/>*/}
        {this.addReturnButton()}
        {this.addSearchInput()}
        {this.getCurrentContent()}
      </div>
    );
  }
}
