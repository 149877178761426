import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import APIUtilities from './APIUtilities.js'
import SkeletonCard from "./SkeletonCard";

export default class PhoneResults extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      firstLoad : true,
      phoneList : {"modele_LIST" : []}
    };
  };

  static propTypes = {
    brandID: PropTypes.number,
    search: PropTypes.string,
    onClickFunction: PropTypes.func
  };

  componentDidMount() {
    this.generatePhoneList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.search !== prevProps.search) {
      this.generatePhoneList();
    }
  }

  async generatePhoneList() {
    const list = await APIUtilities.generatePhoneList(this.props.brandID, this.props.search);
    this.setState({
      firstLoad : false,
      phoneList : list
    });
  }

  selectPhone(phone) {
    this.props.onClickFunction(phone);
  };

  render() {
    if(this.state.firstLoad)
      return <SkeletonCard nbItems="16"/>;

    return (
      <div className="component-grid-list">
        {this.state.phoneList.modele_LIST.map(phone => (
          <div className="component-grid-element component-results-element" id="component-phones-results-element" key={phone.IDModele} onClick={this.selectPhone.bind(this, phone)}>
            <img alt="" src={phone.URL_IMG}/>
            <span className="component-grid-element-title" id="component-phones-title-element">{phone.LibelleModele}</span>
          </div>
        ))}
      </div>);
    }
}
